// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-js": () => import("./../src/pages/CaseStudy.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-casestudy-educrate-js": () => import("./../src/pages/casestudy/Educrate.js" /* webpackChunkName: "component---src-pages-casestudy-educrate-js" */),
  "component---src-pages-casestudy-gubblebums-js": () => import("./../src/pages/casestudy/Gubblebums.js" /* webpackChunkName: "component---src-pages-casestudy-gubblebums-js" */),
  "component---src-pages-casestudy-nba-js": () => import("./../src/pages/casestudy/Nba.js" /* webpackChunkName: "component---src-pages-casestudy-nba-js" */),
  "component---src-pages-casestudy-sohail-js": () => import("./../src/pages/casestudy/Sohail.js" /* webpackChunkName: "component---src-pages-casestudy-sohail-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../src/pages/Resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

